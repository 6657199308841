import { IProject } from '../interfaces/project'
import { IPosition } from '../interfaces/position'
import { ICategory } from '../interfaces/category'

const cacheProject = new Map<string, IProject>()
let cacheAllProjectsLoaded = false
const cachePosition = new Map<string, Map<string, IPosition>>() // Map<projectId, Map<positionId, IPosition>>
let cacheAllPositionsLoaded = false
const cacheCategory = new Map<string, ICategory>()
let cacheAllCategoriesLoaded = false

export function clearCache(): void {
  cacheProject.clear()
  cacheAllProjectsLoaded = false
  cachePosition.clear()
  cacheAllPositionsLoaded = false
  cacheCategory.clear()
  cacheAllCategoriesLoaded = false
}

export function getProject(id: string): IProject | undefined {
  return cacheProject.get(id)
}

export function setProject(project: IProject): void {
  if (project.id !== undefined) {
    let p = getProject(project.id)
    if (p) {
      project = { ...p, ...project }
    }
    // @ts-ignore: Object is possibly 'undefined'.
    cacheProject.set(project.id, project)
  }
}

export function setProjects(projects: IProject[]): void {
  projects.forEach((project) => {
    setProject(project)
  })
  cacheAllProjectsLoaded = true
}

export function getProjects(): IProject[] | null {
  if (cacheAllProjectsLoaded) {
    return Array.from(cacheProject.values())
  }
  return null
}

export function removeProject(id: string): void {
  cacheProject.delete(id)
}

export function getPosition(projectId: string, positionId: string): IPosition | undefined {
  let pos = cachePosition.get(projectId)
  if (pos) {
    return pos.get(positionId)
  }
}

export function setPosition(projectId: string, position: IPosition): void {
  let pos = cachePosition.get(projectId)

  if (position.id !== undefined) {
    if (pos) {
      let p = getPosition(projectId, position.id)
      if (p) {
        position = { ...p, ...position }
      }
      //@ts-ignore: Object is possibly 'undefined'.
      pos.set(position.id, position)
    } else {
      let entry = new Map<string, IPosition>()
      entry.set(position.id, position)
      cachePosition.set(projectId, entry)
    }
  }
}

export function setPositions(projectId: string, positions: IPosition[]): void {
  positions.forEach((position) => {
    setPosition(projectId, position)
  })
  cacheAllPositionsLoaded = true
}

export function getPositions(projectId: string): IPosition[] | null {
  if (cacheAllPositionsLoaded) {
    let pos = cachePosition.get(projectId)
    if (pos) {
      return Array.from(pos.values())
    }
  }
  return null
}

export function removePosition(projectId: string, id: string): void {
  let pos = cachePosition.get(projectId)
  if (pos) {
    pos.delete(id)
  }
}

export function getCategory(id: string): ICategory | undefined {
  return cacheCategory.get(id)
}

export function setCategory(project: ICategory): void {
  if (project.id !== undefined) {
    let p = getCategory(project.id)
    if (p) {
      project = { ...p, ...project }
    }
    // @ts-ignore: Object is possibly 'undefined'.
    cacheCategory.set(project.id, project)
  }
}

export function setCategories(category: ICategory[]): void {
  category.forEach((cat) => {
    setCategory(cat)
  })
  cacheAllCategoriesLoaded = true
}

export function getCategories(): ICategory[] | null {
  if (cacheAllCategoriesLoaded) {
    return Array.from(cacheCategory.values())
  }
  return null
}

export function removeCategory(id: string): void {
  cacheCategory.delete(id)
}
